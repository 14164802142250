import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "../../assets/css/styles.css";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Security from "../../commons/security/Security.js";
import ChangePassword from "../../components/auth/ChangePassword.js";
import Avl from "../../components/avl/Avl.js";
import Videos from "../../components/ayuda/Videos.js";
import Bases from "../../components/bases/Bases.js";
import Rutas from "../../components/rutas/Rutas.js";
import Calendarios from "../../components/calendarios/Calendarios.jsx";
import Categorias from "../../components/categorias/Categorias.js";
import CentrosBeneficios from "../../components/centrosbeneficios/CentrosBeneficios.js";
import CentrosCostos from "../../components/centroscostos/CentrosCostos.js";
import Clases from "../../components/clases/Clases";
import Combustibles from "../../components/combustibles/Combustibles.js";
import CombustiblesLogGrid from "../../components/combustibles/CombustiblesLogGrid.js";
import OTs from "../../components/ordenestrabajo/OTs.js";
import KmMovil from "../../components/reportes/KmMovil.js";
import MovilesInmovilizados from "../../components/reportes/MovilesInmovilizados.js";
import Infracciones from "../../components/infracciones/Infracciones.js";
import Panol from "../../components/panol/Panol.js";
import Panol2 from "../../components/panol2/Panol2.jsx";
import Repuestos from "../../components/repuestos/Repuestos.jsx";
import Correctivos from "../../components/correctivos/Correctivos.js";
import DashboardEconomico from "../../components/dashboards/DashboardEconomico.js";
import DashboardOperativo from "../../components/dashboards/DashboardOperativo.js";
import DashboardAnalitico from "../../components/dashboards/operativo2/DashboardAnalitico.js";
import DashboardOperativo2 from "../../components/dashboards/operativo2/DashboardOperativo2.js";
import Equipamiento from "../../components/equipamiento/Equipamiento.js";
import TipoEquipamiento from "../../components/equipamiento/TipoEquipamiento.js";
import Error from "../../components/errors/Error.js";
import Facturas from "../../components/facturas/Facturas.js";
import Formulario from "../../components/formularios/Formulario.js";
import TipoFormulario from "../../components/formularios/TipoFormulario.js";
import GastoExtra from "../../components/gastoextra/GastoExtra";
import TipoGastoExtra from "../../components/gastoextra/TipoGastoExtra";
import Gerenciadores from "../../components/gerenciadores/Gerenciadores.js";
import GPSDevices from "../../components/gps_devices/GPSDevices.js";
import LlantaAsignaciones from "../../components/llantas/LlantaAsignaciones.js";
import LlantaInspecciones from "../../components/llantas/LlantaInspecciones.js";
import LlantaMarcas from "../../components/llantas/LlantaMarcas.js";
import Llantas from "../../components/llantas/Llantas.js";
import LlantasModelos from "../../components/llantas/LlantasModelos.js";
import Marcas from "../../components/marcas/Marcas.js";
import Modelos from "../../components/modelos/Modelos.js";
import ModeloTipos from "../../components/modelotipos/ModeloTipos.js";
import Motivos from "../../components/motivos/Motivos.js";
import Moviles from "../../components/moviles/Moviles.js";
import Osticket from "../../components/osticket/Osticket.js";
import Perfiles from "../../components/perfiles/Perfiles.js";
import Personas from "../../components/personas/Personas.js";
import PlanMantenimientoPreventivos from "../../components/planmantenimientopreventivos/PlanMantenimientoPreventivos.js";
import Preventivos from "../../components/preventivos/Preventivos.js";
import Regiones from "../../components/regiones/Regiones.js";
import ActualizacionKm from "../../components/reportes/ActualizacionKm.js";
import NoReportan from "../../components/reportes/NoReportan.js";
import Rop from "../../components/rop/Rop.js";
import Servicios from "../../components/servicios/Servicios.js";
import SubRegiones from "../../components/subregiones/Subregiones.js";
import Talleres from "../../components/talleres/Talleres.js";
import Tareas from "../../components/tareas/Tareas.js";
import Tickets from "../../components/tickets/Tickets.js";
import TicketsGestoria from "../../components/ticketsgestoria/TicketsGestoria.js";
import UsuariosMoviles from "../../components/usuariosmoviles/UsuariosMoviles.js";
import Vencimientos from "../../components/vencimientos/Vencimientos.js";
import Bienvenido from "../bienvenido/Bienvenido.js";
import Divisiones from "../divisiones/Divisiones.js";
import LlantaOdometroGrid from "../llantas/LlantaOdometroGrid";
import BISuite from "../biSuite/BiSuite.jsx";
import InsumosMarcas from "../insumosMarcas/InsumosMarcas.jsx";
import { initializePendo } from '../../commons/pendo/pendo.js';

const NotFound = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div>
      <h1 style={{ fontSize: "6rem" }}>
        404 -{" "}
        <FormattedMessage
          id="layout.not_found.pagina_no_encontrada"
          defaultMessage="Página No Encontrada!"
        />
      </h1>
      <Link to="/" style={{ fontSize: "3rem" }}>
        {" "}
        <FormattedMessage
          id="layout.not_found.ir_a_inicio"
          defaultMessage="Ir a Inicio"
        />
      </Link>
    </div>
  </div>
);

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: null,
      codigo: 0,
      showPlayStoreButton: false,
      showLogout: false,
      loadingLogoMenuLateral: true,
      logoMenuLateralUrl: null,
      biParameters: [],
      showBImenu: false,
      //activarBusqueda: false
    };

    this.ajaxHandler = new AjaxHandler();

    //this.handleBusquedaClick = this.handleBusquedaClick.bind(this);
  }

  componentDidMount() {
    this.loadGoogleMaps();
    this.loadStatics();
    this.loadAvatar();
    this.checkBIoptions();
    this.setState({
      showLogout: ConfigBusiness.get("frontend.showLogout") === "true",
    });

    this.ajaxHandler.subscribe(this);

    this.ajaxHandler.getJson("/clienteurl/getGooglePlayCode").then((data) => {
      if (data)
        this.loadPendo(data);
        this.setState({
          ...this.state,
          codigo: data,
          showPlayStoreButton: true,
        });
    });

    fetch(Config.get('apiUrlBase') + '/public/contextos_imagenes/logoMenuLateral', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
    }).then(response => {
        let contentType = response.headers.get('Content-Type');
        if (contentType && contentType.startsWith('image/')) {
            return response.blob();
        } else {
            throw new Error('La respuesta no es una imagen, Content-Type: ' + contentType);
        }
    }).then(blob => {
        let fileUrl = URL.createObjectURL(blob);
        this.setState({ logoMenuLateralUrl: fileUrl });
    }).catch((error) => {
    }).finally(() => {
        this.setState({ loadingLogoMenuLateral: false });
    });
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  loadAvatar() {
    let persona = JSON.parse(localStorage.getItem("persona"));
    let avatar = {
      className: "user-avatar",
      backgroundImage: null,
      initials:
        persona.nombre.substr(0, 1).toUpperCase() +
        persona.apellido.substr(0, 1).toUpperCase(),
    };
    if (persona && persona.foto) {
      let photoUrl =
        Config.get("apiUrlBase") + "/personas/foto/" + persona.foto;
      fetch(photoUrl, {
        method: "GET",
        headers: {
          "Authorization-Token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          }
        })
        .then((imgBlob) => {
          if (imgBlob) {
            avatar.className = "user-avatar-image";
            avatar.backgroundImage = URL.createObjectURL(imgBlob);
            avatar.initials = null;
          }
        })
        .catch(() => {
          avatar.className = "user-avatar";
        })
        .finally(() => {
          this.setState({
            avatar: avatar,
          });
        });
    } else {
      avatar.className = "user-avatar";
      this.setState({
        avatar: avatar,
      });
    }
  }

  loadStatics() {
    document.body.className =
      "vertical-layout vertical-menu 2-columns menu-collapsed fixed-navbar";
    document.body.setAttribute("data-col", "2-columns");

    const appMenuScript = document.createElement("script");
    appMenuScript.src =
      process.env.PUBLIC_URL + "/ui-template/app-assets/js/core/app-menu.js";
    appMenuScript.async = true;
    document.body.appendChild(appMenuScript);
    appMenuScript.onload = () => {
      const appScript = document.createElement("script");
      appScript.src =
        process.env.PUBLIC_URL + "/ui-template/app-assets/js/core/app.js";
      appScript.async = true;
      document.body.appendChild(appScript);
      appScript.onload = () => {
        const customizerScript = document.createElement("script");
        customizerScript.src =
          process.env.PUBLIC_URL +
          "/ui-template/app-assets/js/scripts/customizer.js";
        customizerScript.async = true;
        document.body.appendChild(customizerScript);
      };
    };
  }

  loadGoogleMaps() {
    //script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCE0ykRxC0H2afNmhCoTcVyryOJ2UiEZlM&libraries=places" async defer

    const apiGoogleScript = document.createElement("script");
    apiGoogleScript.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      ConfigBusiness.get("frontend.googleMapsKey") +
      "&libraries=places";
    apiGoogleScript.async = true;
    apiGoogleScript.defer = true;
    apiGoogleScript.id = "google-maps-include";
    document.head.prepend(apiGoogleScript);
  }

  checkBIoptions() {

    const biParameters = [];

    for (let i = 1; i <= 5; i++) {
      const url = (ConfigBusiness.get(`menu.BISuite.submenuBI_${i}.url`));
      const habilitado = (ConfigBusiness.get(`menu.BISuite.submenuBI_${i}.habilitado`));
      let nombre = (ConfigBusiness.get(`menu.BISuite.submenuBI_${i}.nombre`));
      nombre = nombre.length > 23 ? nombre.slice(0, 23) : nombre;

      const permission = Security.hasPermission(`BI_SUITE_${i}`);
      
      const bi = { url, habilitado, nombre, permission };
      biParameters.push(bi);
    }
    //Si alguno de lo BI cumple TODAS las condiciones entonces puedo mostrar el menu
    const showBImenu = biParameters.some( bi => {
      return bi.url != '' && bi.nombre != '' && bi.habilitado === 'true' && bi.permission
    });

    this.setState({
      biParameters,
      showBImenu
    });
    
  }

  loadPendo(accountId) {
    let pendoKey = ConfigBusiness.get('frontend.pendoKey');
    let persona = localStorage.getItem('persona');
    persona = persona ? JSON.parse(persona) : null
    if(pendoKey && persona){
      let visitorId = persona.id;
      let email = persona.email;
      let firstName = persona.nombre;
      let lastName = persona.apellido;
      let perfil = persona.usuario ? (persona.usuario.perfil ? persona.usuario.perfil : null) : null;
      let role = perfil ? perfil.nombre : '';
      let permisos = perfil.permisos;
      let payingStatus = '';
      let accountName = ConfigBusiness.get('generales.codigoCompania') ? ConfigBusiness.get('generales.codigoCompania') : '';

      if(permisos.indexOf('USUARIO_TRABAJAR_CON_TODAS_LAS_BASES') >= 0){
        payingStatus = 'USUARIO_TRABAJAR_CON_TODAS_LAS_BASES';
      }else if(permisos.indexOf('USUARIO_TRABAJA_POR_BASES')>= 0){
        payingStatus = 'USUARIO_TRABAJA_POR_BASES';
      }else if(permisos.indexOf('USUARIO_TRABAJAR_CC')>= 0){
        payingStatus = 'USUARIO_TRABAJAR_CC';
      }else if(permisos.indexOf('USUARIO_TRABAJAR_VEHICULOS')>= 0){
        payingStatus = 'USUARIO_TRABAJAR_VEHICULOS';
      }else if(permisos.indexOf('USUARIO_TRABAJAR_CIADEORIGEN') >= 0){
        payingStatus = 'USUARIO_TRABAJAR_CIADEORIGEN';
      }else if(permisos.indexOf('USUARIO_TRABAJAR_FACTURACION') >= 0){
        payingStatus = 'USUARIO_TRABAJAR_FACTURACION';
      }

      initializePendo({
        apiKey: pendoKey,
        visitorId: visitorId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: role,
        accountId: accountId,
        accountName: accountName,
        payingStatus: payingStatus,
      }); 
    }
  }
  /*
		handleBusquedaClick(){
			console.log(this.state.activarBusqueda);
			this.setState({activarBusqueda: !this.state.activarBusqueda});
		}*/
    
  render() {
    let persona = JSON.parse(localStorage.getItem("persona"));
    let nombreApellido = persona.nombre + " " + persona.apellido;
    let avatarInitials =
      this.state.avatar && this.state.avatar.initials
        ? this.state.avatar.initials
        : "";
    let avatarClassName =
      this.state.avatar && this.state.avatar.className
        ? this.state.avatar.className
        : "";
    let avatarStyle =
      this.state.avatar && this.state.avatar.backgroundImage
        ? {
            backgroundImage: "url(" + this.state.avatar.backgroundImage + ")",
          }
        : {};
    let showButton = this.state.showPlayStoreButton && this.state.codigo !== 0;

    return (
      <Router>
        <React.Fragment>
          {/* Header */}
          <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-vec navbar-shadow">
            <div className="navbar-wrapper">
              <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                  <li className="nav-item mobile-menu d-md-none mr-auto">
                    <a
                      id="mobileMenuToggle"
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                    >
                      <i className="ft-menu font-large-1"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="navbar-brand" href="/">
                      <img
                        className="brand-logo"
                        alt="VEC"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/company/logo-app.png"
                        }
                      />
                      <h3 className="brand-text">
                        {!this.state.loadingLogoMenuLateral ? (
                            this.state.logoMenuLateralUrl ? 
                                <img src={this.state.logoMenuLateralUrl} alt="" width="180" /> 
                              : <img alt="DirecTV" src={process.env.PUBLIC_URL + "/images/company/logo-company.png" } width="180" />
                          )
                          
                        : null}
                      </h3>
                    </a>
                  </li>
                  <li className="nav-item d-md-none">
                    <a
                      className="nav-link open-navbar-container"
                      data-toggle="collapse"
                      data-target="#navbar-mobile"
                    >
                      <i className="la la-ellipsis-v"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="navbar-container content">
                <div className="collapse navbar-collapse" id="navbar-mobile">
                  <ul className="nav navbar-nav mr-auto float-left">
                    <li className="nav-item d-none d-md-block">
                      <a className="nav-link nav-menu-main menu-toggle hidden-xs">
                        <i className="ft-menu"></i>
                      </a>
                    </li>
                    <li className="nav-item d-none d-md-block">
                      <a className="nav-link nav-link-expand">
                        <i className="ficon ft-maximize"></i>
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav float-right">
                    <li className="dropdown dropdown-user nav-item">
                      {this.state.showLogout ? (
                        <>
                          <a
                            className="dropdown-toggle nav-link dropdown-user-link"
                            data-toggle="dropdown"
                          >
                            <span className="mr-1">
                              <span className="user-name text-bold-700">
                                {nombreApellido}
                              </span>
                            </span>
                            <span className="avatar avatar-online">
                              <div
                                ref="divAvatar"
                                className={avatarClassName}
                                style={avatarStyle}
                              >
                                {avatarInitials}
                              </div>
                            </span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <Link to="/cambiar-clave" className="dropdown-item">
                              <i className="ft-lock"></i>
                              <FormattedMessage
                                id="layout.dropdown_menu_right.select_cambiar_contrasenia"
                                defaultMessage=" Cambiar contraseña"
                              />
                            </Link>
                            <div className="dropdown-divider"></div>
                            <a
                              className="dropdown-item"
                              onClick={Security.logout}
                            >
                              <i className="ft-power"></i>
                              <FormattedMessage
                                id="layout.dropdown_menu_right.cerrar_sesion"
                                defaultMessage=" Cerrar Sesión"
                              />
                            </a>
                          </div>
                        </>
                      ) : (
                        <a className="dropdown-toggle nav-link dropdown-user-link">
                          <span className="mr-1">
                            <span className="user-name text-bold-700">
                              {nombreApellido}
                            </span>
                          </span>
                          <span className="avatar avatar-online">
                            <div
                              ref="divAvatar"
                              className={avatarClassName}
                              style={avatarStyle}
                            >
                              {avatarInitials}
                            </div>
                          </span>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          {/* Menu */}
          <div
            className="main-menu menu-fixed menu-vec menu-accordion menu-shadow "
            data-scroll-to-active="true"
          >
            <div className="main-menu-content">
              <ul
                className="navigation navigation-main"
                id="main-menu-navigation"
                data-menu="menu-navigation"
              >
                {Security.renderIfHasAnyPermission(
                  [
                    "DASHBOARD_OPERATIVO2_VISUALIZAR",
                    "DASHBOARD_COMERCIAL_VISUALIZAR",
                  ],
                  <li className="nav-item">
                    <a>
                      <i className="la la-bar-chart"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_dashboards.title"
                          defaultMessage="Dashboards"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasAnyPermission(
                        "DASHBOARD_OPERATIVO2_VISUALIZAR",
                        <li>
                          <Link to="/dashboardOperativo2">
                            <FormattedMessage
                              id="layout.menu_navigation.select_dashboards.operativo"
                              defaultMessage="Operativo"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasAnyPermission(
                        "DASHBOARD_ANALITICO_VISUALIZAR",
                        <li>
                          <Link to="/dashboardAnalitico">
                            <FormattedMessage
                              id="layout.menu_navigation.select_dashboards.operativo.analitico"
                              defaultMessage="Analítico"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasAnyPermission(
                        "DASHBOARD_COMERCIAL_VISUALIZAR",
                        <li className="menuItem">
                          <Link to="/dashboardEconomico">
                            <FormattedMessage
                              id="layout.menu_navigation.select_dashboards.economico"
                              defaultMessage="Económico"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {Security.hasAnyPermission(
                  [
                    "BI_SUITE_1",
                    "BI_SUITE_2",
                    "BI_SUITE_3",
                    "BI_SUITE_4",
                    "BI_SUITE_5",
                  ]) && this.state.showBImenu ?
                  <li className="nav-item">
                    <a>
                      <i className="la la-magic"></i>
                      <span className="menu-title">
                        BI Suite
                      </span>
                    </a>
                    <ul className="menu-content">
                      {this.state.biParameters.map((bi, i) => {
                        const n = i + 1;
                        const menuItem = Security.hasPermission(
                          `BI_SUITE_${n}`) && bi.habilitado === 'true' && bi.url != '' && bi.nombre != ''  ?
                          <li key={i}>
                            <Link to={`/BI_${n}`}>
                              {bi.nombre}
                            </Link>
                          </li> : null;
                        return menuItem;
                      })}
                    </ul>
                  </li>
                : ''}
                {Security.renderIfHasPermission(
                  "CALENDARIO_LISTAR",
                  <li className="nav-item menuItem">
                    <Link to="/calendarios">
                      <i className="la la-calendar"></i>
                      <span className="menu-title">
                        <FormattedMessage
                          id="layout.menu_navigation.select_mantenimiento.calendario"
                          defaultMessage="Calendario "
                        />
                      </span>
                    </Link>
                  </li>
                )}
                {Security.renderIfHasPermission(
                  "TICKETS_LISTAR",
                  <li className="nav-item-collapser">
                    <Link to="/tickets">
                      <i className="las la-clipboard-list"></i>
                      <span className="menu-title">
                        <FormattedMessage
                          id="layout.menu_navigation.select_mantenimiento.tickets"
                          defaultMessage="Tickets"
                        />
                      </span>
                    </Link>
                  </li>
                )}
                {Security.renderIfHasPermission(
                  "ROPS_LISTAR",
                  <li className="nav-item-collapser">
                    <Link to="/rop">
                      <i className="las la-clipboard-list"></i>
                      <span className="menu-title">
                        <FormattedMessage
                          id="layout.menu_navigation.select_mantenimiento.rop"
                          defaultMessage="Reportes Operador"
                        />
                      </span>
                    </Link>
                  </li>
                )}
                {/* {Security.renderIfHasAnyPermission(
                  ["PANOL_PLANTILLAS_LISTAR", "PANOL_CONFIGURACION_LISTAR"],
                  <li className="nav-item menuItem panol">
                    <a>
                      <i className="la la-clipboard"></i>
                      <span
                        className="menu-title combustible"
                        data-i18n="nav.dash.main"
                      >
                        <FormattedMessage
                          id="layout.menu_navigation.select_paniol.title"
                          defaultMessage="Pañol"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      <li className="nav-item">
                        <Link to="/panol">
                          <FormattedMessage
                            id="layout.menu_navigation.select_paniol.stock"
                            defaultMessage="Stock"
                          />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/panol/plantillas">
                          <FormattedMessage
                            id="layout.menu_navigation.select_paniol.plantillas"
                            defaultMessage="Plantillas"
                          />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/panol/config">
                          <FormattedMessage
                            id="layout.menu_navigation.select_configuracion"
                            defaultMessage="Configuración"
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                )} */}
                {Security.renderIfHasAnyPermission(
                  ["PANOL_PLANTILLAS_LISTAR", "PANOL_CONFIGURACION_LISTAR"],
                  <li className="nav-item menuItem panol2">
                    <a>
                      <i className="la la-clipboard"></i>
                      <span
                        className="menu-title combustible"
                        data-i18n="nav.dash.main"
                      >
                        <FormattedMessage
                          id="layout.menu_navigation.select_paniol2.title"
                          defaultMessage="Pañol2"
                        />
                      </span>
                    </a>
                    {ConfigBusiness.get("panol.stock.habilitado") === "true" ?
                      <ul className="menu-content">
                        <li className="nav-item">
                          <Link to="/panol2">
                            <FormattedMessage
                              id="layout.menu_navigation.select_paniol.stock"
                              defaultMessage="Stock"
                            />
                          </Link>
                        </li>
                      </ul>
                    : null}
                    <ul className="menu-content">
                      <li className="nav-item">
                        <Link to="/repuestos">
                          <FormattedMessage
                            id="layout.menu_navigation.select_paniol.repuestos"
                            defaultMessage="Repuestos"
                          />
                        </Link>
                      </li>
                    </ul>
                    {Security.hasPermission('ITEMS_MARCAS_LISTAR') &&
                    ConfigBusiness.get('panol.marca.habilitado') === 'true' ?
                      <ul className="menu-content">
                        <li className="nav-item">
                          <Link to="/insumos_marcas">
                            <FormattedMessage
                              id="layout.menu_navigation.select_paniol.insumos_marcas"
                              defaultMessage="Marcas"
                            />
                          </Link>
                        </li>
                      </ul>
                    : ''}
                  </li>
                )}

                {Security.renderIfHasAnyPermission(
                  [
                    "TICKETS_LISTAR",
                    "MANTENIMIENTOS_CORRECTIVOS_CREAR",
                    "TICKETS_GESTORIA_CREAR",
                    "PREVENTIVOS_LISTAR",
                    "VENCIMIENTOS_LISTAR",
                    "CALENDARIO_LISTAR",
                  ],
                  <li className="nav-item">
                    <a>
                      <i className="la la-wrench"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_mantenimiento.title"
                          defaultMessage="Mantenimiento"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "MANTENIMIENTOS_CORRECTIVOS_CREAR",
                        <li>
                          <Link to="/correctivos/add">
                            <FormattedMessage
                              id="layout.menu_navigation.select_mantenimiento.correctivos"
                              defaultMessage="Correctivos"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "PREVENTIVOS_LISTAR",
                        <li className="menuItem">
                          <Link to="/preventivos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_mantenimiento.preventivos"
                              defaultMessage="Preventivos"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "VENCIMIENTOS_LISTAR",
                        <li className="menuItem">
                          <Link to="/vencimientos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_mantenimiento.vencimientos"
                              defaultMessage="Vencimientos"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "PLANES_MANTENIMIENTO_LISTAR",
                        <li>
                          <Link to="/planmantenimientopreventivos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_general.planes_mantenimiento"
                              defaultMessage="Planes de Mantenimiento"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "SERVICIOS_LISTAR",
                        <li>
                          <Link to="/servicios">
                            <FormattedMessage
                              id="layout.menu_navigation.select_general.servicios"
                              defaultMessage="Servicios"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "TAREAS_LISTAR",
                        <li>
                          <Link to="/tareas">
                            <FormattedMessage
                              id="layout.menu_navigation.select_general.tareas"
                              defaultMessage="Tareas"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {Security.renderIfHasAnyPermission(
                  ["COMBUSTIBLES_LISTAR", "COMBUSTIBLES_LOG_VISUALIZAR"],
                  <li className="nav-item menuItem">
                    <a>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/combustibles/surtidor.svg"
                        }
                        className="combustible"
                      ></img>
                      <span
                        className="menu-title combustible"
                        data-i18n="nav.dash.main"
                      >
                        <FormattedMessage
                          id="layout.menu_navigation.select_combustible.title"
                          defaultMessage="Combustible"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "COMBUSTIBLES_LISTAR",
                        <li>
                          <Link to="/combustibles">
                            <FormattedMessage
                              id="layout.menu_navigation.select_combustible.cargas"
                              defaultMessage="Cargas"
                            />
                          </Link>
                        </li>
                      )}

                      {Security.renderIfHasPermission(
                        "COMBUSTIBLES_LOG_VISUALIZAR",
                        <li>
                          <Link to="/combustiblesLog">
                            <FormattedMessage
                              id="layout.menu_navigation.select_combustible.log_altas"
                              defaultMessage="Log de Altas"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {Security.renderIfHasAnyPermission(
                  ["CHECKLIST_LISTAR"],
                  ConfigBusiness.get("checklist").habilitado === "true" && (
                    <li className="nav-item">
                      <a>
                        <i className="la la-paste"></i>
                        <span className="menu-title" data-i18n="nav.dash.main">
                          <FormattedMessage
                            id="layout.menu_navigation.select_checklist.title"
                            defaultMessage="Checklist"
                          />
                        </span>
                      </a>
                      <ul className="menu-content">
                        {Security.renderIfHasPermission(
                          "CHECKLIST_LISTAR_HISTORICO",
                          <li className="menuItem">
                            <Link to="/formulario">
                              <FormattedMessage
                                id="layout.menu_navigation.select_checklist.historico"
                                defaultMessage="Histórico"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "CHECKLIST_LISTAR_TIPO_FORMULARIOS",
                          <li className="menuItem">
                            <Link to="/tipoformulario">
                              <FormattedMessage
                                id="layout.menu_navigation.select_checklist.tipos_formularios"
                                defaultMessage="Tipos de Formularios"
                              />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )
                )}

                {Security.renderIfHasAnyPermission(
                  ["INFRACCIONES_LISTAR"],
                  ConfigBusiness.get("infracciones").habilitado === "true" && (
                    <li className="nav-item menuItem">
                      <Link to="/moduloInfracciones">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/infracciones/Infraciones-Menu.svg"
                          }
                          className="infracciones"
                        ></img>
                        <span
                          className="menu-title combustible"
                          data-i18n="nav.dash.main"
                        >
                          <FormattedMessage
                            id="layout.menu_navigation.select_infracciones.infracciones"
                            defaultMessage="Infracciones"
                          />
                        </span>
                      </Link>
                    </li>
                  )
                )}

                {Security.renderIfHasAnyPermission(
                  [
                    "MOVILES_LISTAR",
                    "MARCAS_LISTAR",
                    "MODELOS_LISTAR",
                    "MODELO_TIPOS_LISTAR",
                    "GPS_DEVICES_LIST",
                  ],
                  <li className="nav-item">
                    <a>
                      <i className="la la-car"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_moviles.title"
                          defaultMessage="Móviles"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "MOVILES_LISTAR",
                        <li>
                          <Link to="/moviles">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.moviles"
                              defaultMessage="Móviles"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "MARCAS_LISTAR",
                        <li className="menuItem">
                          <Link to="/marcas">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.marcas"
                              defaultMessage="Marcas"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "MOVILES_LISTAR",
                        <li className="menuItem">
                          <Link to="/clases">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.clases"
                              defaultMessage="Clases"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "MODELOS_LISTAR",
                        <li className="menuItem">
                          <Link to="/modelos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.modelos"
                              defaultMessage="Modelos"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "MODELO_TIPOS_LISTAR",
                        <li className="menuItem">
                          <Link to="/modelotipos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.tipos_modelo"
                              defaultMessage="Tipos de Modelo"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "GPS_DEVICES_LIST",
                        <li className="menuItem">
                          <Link to="/gps/brands">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.dispositivos_GPS"
                              defaultMessage="Dispositivos GPS"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {Security.renderIfHasAnyPermission(
                  [
                    "LLANTAS_ASIGNACIONES_LISTAR",
                    "LLANTAS_LISTAR",
                    "LLANTAS_MODELOS_LISTAR",
                    "LLANTAS_MARCAS_LISTAR",
                    "LLANTAS_INSPECCIONES_LISTAR",
                  ],
                  ConfigBusiness.get("llantas").habilitado === "true" && (
                    <li className="nav-item">
                      <a>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/llantas/llanta-layout.svg"
                          }
                          className="llanta"
                        ></img>
                        <span className="menu-title" data-i18n="nav.dash.main">
                          <FormattedMessage
                            id="layout.menu_navigation.select_llantas.title"
                            defaultMessage="Llantas"
                          />
                        </span>
                        <span className="badge badge rounded badge-info float-right mr-2">
                        <FormattedMessage
                          id="layout.menu_navigation.select_equipamiento.llantas_nuevo"
                          defaultMessage="Nuevo!"
                        />
                      </span>
                      </a>
                      <ul className="menu-content">
                        {Security.renderIfHasPermission(
                          "LLANTAS_LISTAR",
                          <li>
                            <Link to="/llantas">
                              <FormattedMessage
                                id="layout.menu_navigation.select_llantas.llantas"
                                defaultMessage="Llantas"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "LLANTAS_ASIGNACIONES_LISTAR",
                          <li>
                            <Link to="/llantaasignaciones">
                              <FormattedMessage
                                id="layout.menu_navigation.select_llantas.llanta_asignaciones"
                                defaultMessage="Asignaciones"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "LLANTAS_INSPECCIONES_LISTAR",
                          <li>
                            <Link to="/llantainspecciones">
                              <FormattedMessage
                                id="layout.menu_navigation.select_llantas.llanta_inspecciones"
                                defaultMessage="Inspecciones"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "LLANTAS_MARCAS_LISTAR",
                          <li>
                            <Link to="/llantamarcas">
                              <FormattedMessage
                                id="layout.menu_navigation.select_llantas.marcas"
                                defaultMessage="Marcas"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "LLANTAS_MODELOS_LISTAR",
                          <li>
                            <Link to="/llantamodelos">
                              <FormattedMessage
                                id="layout.menu_navigation.select_llantas.modelos"
                                defaultMessage="Modelos"
                              />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )
                )}

                {Security.renderIfHasAnyPermission(
                  ["GASTOS_LISTAR_HISTORICO", "GASTOS_TIPO_LISTAR"],
                    <li className="nav-item">
                      <a>
                        <i className="la la-money"></i>
                        <span className="menu-title" data-i18n="nav.dash.main">
                          <FormattedMessage
                            id="layout.menu_navigation.select_gastos.title"
                            defaultMessage="Gastos Extras"
                          />
                        </span>
                      </a>
                      <ul className="menu-content">
                        {Security.renderIfHasPermission(
                          "GASTOS_LISTAR_HISTORICO",
                          <li className="menuItem">
                            <Link to="/gastoExtra">
                              <FormattedMessage
                                id="layout.menu_navigation.select_gastos.historico"
                                defaultMessage="Histórico"
                              />
                            </Link>
                          </li>
                        )}
                        {Security.renderIfHasPermission(
                          "GASTOS_TIPO_LISTAR",
                          <li className="menuItem">
                            <Link to="/tipoGastoExtra">
                              <FormattedMessage
                                id="layout.menu_navigation.select_gastos.tipos_formularios"
                                defaultMessage="Tipos de Gastos"
                              />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}


                {Security.renderIfHasAnyPermission(
                  ["EQUIPAMIENTO_LISTAR", "EQUIPAMIENTO_TIPOS_LISTAR"],
                  <li className="nav-item">
                    <a>
                      <i className="la la-toolbox"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_equipamiento.title"
                          defaultMessage="Equipamiento"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "EQUIPAMIENTO_LISTAR",
                        <li className="menuItem">
                          <Link to="/equipamiento">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.equipamiento"
                              defaultMessage="Equipamiento"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "EQUIPAMIENTO_TIPOS_LISTAR",
                        <li className="menuItem">
                          <Link to="/tipoequipamiento">
                            <FormattedMessage
                              id="layout.menu_navigation.select_moviles.tipos_equipamiento"
                              defaultMessage="Tipos de Equipamiento"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {Security.renderIfHasPermission(
                  "PERSONAS_LISTAR",
                  <li className="nav-item menuItem">
                    <Link to="/personas">
                      <i className="la la-user"></i>
                      <span className="menu-title">
                        <FormattedMessage
                          id="layout.menu_navigation.select_recursos.personas_label"
                          defaultMessage="Personas"
                        />
                      </span>
                    </Link>
                  </li>
                )}

                {Security.renderIfHasPermission(
                  "GASTOS_EXTRA_ACCEDER",
                  <li className="nav-item menuItem">
                    <Link to="/gastosextra">
                      <i class="las la-dollar-sign"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.gastos_extra.title"
                          defaultMessage="Gastos Extra"
                        />
                      </span>
                    </Link>
                  </li>
                )}

                {Security.renderIfHasPermission(
                  "AVL_ACCEDER",
                  <li className="nav-item menuItem">
                    <Link to="/avl">
                      <i className="la la-map-marker"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_AVL_mapa.title"
                          defaultMessage="Mapa"
                        />
                      </span>
                    </Link>
                  </li>
                )}

                {Security.renderIfHasAnyPermission(
                  ["INFORMES_ACCEDER"],
                  <li className="nav-item menuItem">
                    <a>
                      <i className="la la-pie-chart"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_informes.title"
                          defaultMessage="Informes"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "REPORTES_NO_REPORTAN",
                        <li>
                          <Link to="/noreportan">
                            <FormattedMessage
                              id="layout.menu_navigation.select_informes.no_reportan"
                              defaultMessage="No Reportan"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "REPORTES_ACTUALIZACION_KM",
                        <li>
                          <Link to="/actualizacionkm">
                            <FormattedMessage
                              id="layout.menu_navigation.select_informes.actualizacion_Medidor"
                              defaultMessage="Actualización Medidor"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "REPORTES_KM_MOVIL_LISTAR",
                        <li>
                          <Link to="/kmMovil">
                            <FormattedMessage
                              id="layout.menu_navigation.select_informes.Medidor_por_movil"
                              defaultMessage="Medidor por Móvil"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "REPORTES_MOVILES_INMOVILIZADOS",
                        <li>
                          <Link to="/movilInmovil">
                            <FormattedMessage
                              id="layout.menu_navigation.select_informes.moviles_inmovilizados"
                              defaultMessage="Móviles Inmovilizados"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {Security.renderIfHasAnyPermission(
                  ["OT_LISTAR", "TICKETS_GESTORIA_CREAR"],
                  <li className="nav-item">
                    <a>
                      <i className="la la-briefcase"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_gestoria.title"
                          defaultMessage="Gestoría"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "TICKETS_GESTORIA_CREAR",
                        <li>
                          <Link to="/ticketsgestoria/add">
                            <FormattedMessage
                              id="layout.menu_navigation.select_gestoria.tickets_gestoria"
                              defaultMessage="Tickets de Gestoría"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "OT_LISTAR",
                        <li className="nav-item-collapser">
                          <Link to="/ordenestrabajo">
                            <FormattedMessage
                              id="layout.menu_navigation.select_gestoria.ordenes_de_trabajo"
                              defaultMessage="Órdenes de Trabajo"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {Security.renderIfHasAnyPermission(
                  [
                    "PERFILES_LISTAR",
                    "FACTURAS_LISTAR",
                    "CENTRO_COSTOS_LISTAR",
                    "CENTRO_BENEFICIOS_LISTAR",
                    "CATEGORIAS_LISTAR",
                    "BASES_LISTAR",
                    "GERENCIADORES_LISTAR",
                    "TALLERES_LISTAR",
                    "REGIONES_LISTAR",
                    "SUBREGIONES_LISTAR",
                    "REPORTAR_PROBLEMA",
                  ],
                  <li className="nav-item menuItem">
                    <a>
                      <i className="la la-cog"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_administracion.title"
                          defaultMessage="Administracion"
                        />
                      </span>
                    </a>
                    <ul className="menu-content">
                      {Security.renderIfHasPermission(
                        "PERFILES_LISTAR",
                        <li>
                          <Link to="/perfiles">
                            <FormattedMessage
                              id="layout.menu_navigation.select_seguridad.perfiles_permisos_seguridad"
                              defaultMessage="Seguridad"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "FACTURAS_LISTAR",
                        <li className="nav-item-collapser">
                          <Link to="/facturas">
                            <FormattedMessage
                              id="layout.menu_navigation.select_facturacion.facturas"
                              defaultMessage="Facturas"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "CENTRO_COSTOS_LISTAR",
                        <li>
                          <Link to="/centrosCostos">
                            <FormattedMessage
                              id="layout.menu_navigation.select_divisiones.centros_costos"
                              defaultMessage="Centros de Costos"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "CENTRO_BENEFICIOS_LISTAR",
                        <li>
                          <Link to="/centrosBeneficios">
                            <FormattedMessage
                              id="layout.menu_navigation.select_divisiones.centros_beneficios"
                              defaultMessage="Centros de Beneficios"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "CATEGORIAS_LISTAR",
                        <li>
                          <Link to="/categorias">
                            <FormattedMessage
                              id="layout.menu_navigation.select_general.categorías"
                              defaultMessage="Categorías"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "BASES_LISTAR",
                        <li>
                          <Link to="/bases">
                            <FormattedMessage
                              id="layout.menu_navigation.select_recursos.bases"
                              defaultMessage="Bases"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "RUTAS_LISTAR",
                        <li>
                          <Link to="/rutas">
                            <FormattedMessage
                              id="Rutas"
                              defaultMessage="Rutas"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "GERENCIADORES_LISTAR",
                        <li>
                          <Link to="/gerenciadores">
                            <FormattedMessage
                              id="layout.menu_navigation.select_proveedores.gerenciadores"
                              defaultMessage="Gerenciadores"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "TALLERES_LISTAR",
                        <li>
                          <Link to="/talleres">
                            <FormattedMessage
                              id="layout.menu_navigation.select_proveedores.talleres"
                              defaultMessage="Talleres"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "REGIONES_LISTAR",
                        <li>
                          <Link to="/regiones">
                            <FormattedMessage
                              id="layout.menu_navigation.select_regionalizacion.regiones"
                              defaultMessage="Regiones"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "SUBREGIONES_LISTAR",
                        <li>
                          <Link to="/subRegiones">
                            <FormattedMessage
                              id="layout.menu_navigation.select_regionalizacion.Subregiones"
                              defaultMessage="Subregiones"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "REPORTAR_PROBLEMA",
                        <li>
                          <Link to="/osticket">
                            <FormattedMessage
                              id="layout.menu_navigation.select_reportar_problema.title"
                              defaultMessage="Reportar Problema"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "MOTIVOS_LISTAR",
                        <li>
                          <Link to="/motivos">
                            <FormattedMessage
                              id="Motivo"
                              defaultMessage="Motivo"
                            />
                          </Link>
                        </li>
                      )}
                      {Security.renderIfHasPermission(
                        "DIVISIONES_LISTAR",
                        <li>
                          <Link to="/divisiones">
                            <FormattedMessage
                              id="Gestión_de_División"
                              defaultMessage="Gestión de División"
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {Security.renderIfHasPermission(
                  "AYUDA_ACCEDER",
                  <li className="nav-item menuItem">
                    <a>
                      <i className="la la-support"></i>
                      <span className="menu-title" data-i18n="nav.dash.main">
                        <FormattedMessage
                          id="layout.menu_navigation.select_ayuda.title"
                          defaultMessage="Ayuda"
                        />
                      </span>
                    </a>
                    {/* <ul className="menu-content addHeight">
                      <li>
                        <Link to="/videos">
                          <FormattedMessage
                            id="layout.menu_navigation.select_ayuda.video_tutoriales"
                            defaultMessage="Video Tutoriales"
                          />
                        </Link>
                      </li>
                      </ul> */}
                      <ul className="menu-content addHeight">
                      <li>
                        <a href="mailto:soporte@vecfleet.io" target="_blank">
                          <FormattedMessage
                            id="layout.menu_navigation.select_ayuda.soporte"
                            defaultMessage="Soporte"
                          />
                        </a>
                      </li>
                      </ul>
                    <ul className="menu-content addHeight">
                      <li>
                        <a href="https://biblioteca.vecfleet.io/knowledge" target="_blank">
                          <FormattedMessage
                            id="layout.menu_navigation.select_ayuda.guia_usuario"
                            defaultMessage="Guia de usuario"
                          />
                        </a>
                      </li>

                    </ul>
                  </li>
                )}
              </ul>
              {Security.renderIfHasPermission(
                "MOSTRAR_BOTON_GOOGLE_PLAY",
                ConfigBusiness.get("generales").mostrarBotonGooglePlay &&
                  showButton && (
                    <div className="google-play-button-div">
                      <a
                        style={{ display: "flex", justifyContent: "center" }}
                        className="google-play-button-a"
                        href="https://play.google.com/store/apps/details?id=org.vecfleet.io"
                      >
                        <img
                          style={{ width: "50%" }}
                          className="google-play-button-img"
                          alt="Disponible en Google Play"
                          src="https://play.google.com/intl/en_us/badges/images/generic/es_badge_web_generic.png"
                        />
                      </a>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p
                          style={{ fontSize: "0.90rem" }}
                          className="google-play-button-p"
                        >
                          <FormattedMessage
                            id="layout.google_play_button_p.ingresa_con_tu_usuario_y_contrasenia"
                            defaultMessage="Ingresa con tu usuario y contraseña y el codigo "
                          />
                          {this.state.codigo}
                        </p>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>

          {/* Content */}
          <div className="app-content content">
            <Switch>
              {Security.hasPermission("DASHBOARD_OPERATIVO2_VISUALIZAR") && ConfigBusiness.get("frontend.redirectToWelcomeScreen") === "false" ? (
                <Route exact path="/" component={DashboardOperativo2} />
              ) : (
                <Route exact path="/" component={Bienvenido} />
              )}
              <Route path="/error" component={Error} />
              <Route path="/biUrlError" render={(props) => <Error {...props} biError/>}/>
              <Route path="/cambiar-clave" component={ChangePassword} />
              <Route
                path="/dashboardOperativo"
                component={DashboardOperativo}
              />
              <Route
                path="/dashboardOperativo2"
                component={DashboardOperativo2}
              />
              <Route
                path="/dashboardAnalitico"
                component={DashboardAnalitico}
              />
              <Route
                path="/dashboardEconomico"
                component={DashboardEconomico}
              />
              <Route path="/perfiles" component={Perfiles} />
              <Route path="/personas" component={Personas} />
              <Route path="/moviles" component={Moviles} />
              <Route path="/clases" component={Clases} />
              <Route path="/llantas" component={Llantas} />
              <Route path="/llantamarcas" component={LlantaMarcas} />
              <Route path="/llantamodelos" component={LlantasModelos} />
              <Route
                path="/llantaasignaciones"
                component={LlantaAsignaciones}
              />
              <Route
                path="/llantainspecciones"
                component={LlantaInspecciones}
              />
              <Route path="/bases" component={Bases} />
              <Route path="/usuariosmoviles" component={UsuariosMoviles} />
              <Route path="/avl" component={Avl} />
              <Route path="/moviles" component={Moviles} />
              <Route path="/avl" component={Avl} />
              <Route path="/gerenciadores" component={Gerenciadores} />
              <Route path="/bases" component={Bases} />
              <Route path="/rutas" component={Rutas} />
              <Route path="/correctivos" component={Correctivos} />
              <Route path="/ticketsGestoria" component={TicketsGestoria} />
              <Route path="/tickets" component={Tickets} />
              <Route path="/osticket" component={Osticket} />
              <Route path="/talleres" component={Talleres} />
              <Route path="/calendarios" component={Calendarios} />
              <Route path="/regiones" component={Regiones} />
              <Route path="/subRegiones" component={SubRegiones} />
              <Route path="/marcas" component={Marcas} />
              <Route path="/modelos" component={Modelos} />
              <Route path="/modelotipos" component={ModeloTipos} />
              <Route path="/gps/brands" component={GPSDevices} />
              <Route path="/centroscostos" component={CentrosCostos} />
              <Route path="/centrosbeneficios" component={CentrosBeneficios} />
              <Route path="/vencimientos" component={Vencimientos} />
              <Route path="/servicios" component={Servicios} />
              <Route path="/tareas" component={Tareas} />
              <Route
                path="/planmantenimientopreventivos"
                component={PlanMantenimientoPreventivos}
              />
              <Route path="/preventivos" component={Preventivos} />
              <Route path="/noreportan" component={NoReportan} />
              <Route path="/actualizacionkm" component={ActualizacionKm} />
              <Route path="/facturas" component={Facturas} />
              <Route path="/categorias" component={Categorias} />
              <Route path="/videos" component={Videos} />
              {<Route path="/combustibles" component={Combustibles} />}
              <Route path="/combustiblesLog" component={CombustiblesLogGrid} />
              <Route path="/KmMovil" component={KmMovil} />
              <Route path="/ordenestrabajo" component={OTs} />
              <Route path="/movilInmovil" component={MovilesInmovilizados} />
              <Route path="/moduloInfracciones" component={Infracciones} />
              <Route path="/panol" component={Panol} />
              <Route path="/panol2" component={Panol2} />
              <Route path="/repuestos" component={Repuestos} />
              <Route path="/insumos_marcas" component={InsumosMarcas} />
              <Route path="/tipoEquipamiento" component={TipoEquipamiento} />
              <Route path="/equipamiento" component={Equipamiento} />
              <Route path="/tipoFormulario" component={TipoFormulario} />
              <Route path="/formulario" component={Formulario} />
              <Route path="/rop" component={Rop} />
              <Route path="/gastoExtra" component={GastoExtra} />
              <Route path="/tipogastoExtra" component={TipoGastoExtra} />
              <Route path="/rop" component={Rop} />
              <Route path="/motivos" component={Motivos} />
              <Route path="/divisiones" component={Divisiones} />
              <Route path="/BI_1" component={BISuite} />
              <Route path="/BI_2" component={BISuite} />
              <Route path="/BI_3" component={BISuite} />
              <Route path="/BI_4" component={BISuite} />
              <Route path="/BI_5" component={BISuite} />
              <Route path="/*" component={NotFound} />
              <Route
                path="/llantaodometrogrid"
                component={LlantaOdometroGrid}
              />
            </Switch>
          </div>

          {/* Footer */}
          <footer className="footer footer-static footer-light navbar-border navbar-shadow">
            <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
              {/*<span className="float-md-left d-block d-md-inline-block">Copyright &copy; 2018 <img className="logo-footer-ga" alt="VEC Fleet" src={LogoFooterGreen} height="15px" /> Todos los derechos reservados. </span>*/}
              <span className="float-md-left d-block d-md-inline-block">
                <FormattedMessage
                  id="layout.navbar_footer.copyright "
                  defaultMessage="Copyright "
                />
                &copy; 2018{" "}
                <img
                  className="logo-footer-ga"
                  alt="VEC Fleet"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/company/logo-copyright.png"
                  }
                />
                <FormattedMessage
                  id="layout.navbar_footer.todos_los_derechos_reservados"
                  defaultMessage=" Todos los derechos reservados. v"
                />
                {process.env.REACT_APP_VERSION}
                {" | "}
                <a href={ConfigBusiness.get('frontend.politicaPrivacidad.url')} target="_blank" rel="noopener noreferrer">
                    {this.props.intl.formatMessage({ id: 'frontend.footer.politicaPrivacidad', defaultMessage: 'Política de Privacidad' })}
                </a>
              </span>
              <span className="float-md-right d-block d-md-inline-blockd-none d-lg-block">
                <FormattedMessage
                  id="layout.navbar_footer.powered_by"
                  defaultMessage="Powered by: "
                />
                <img
                  alt="VEC"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/company/logo-powered-by.png"
                  }
                  height="30px"
                />
              </span>
            </p>
          </footer>
        </React.Fragment>
      </Router>
    );
  }
}

export default injectIntl(Layout);
